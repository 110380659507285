import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect} from 'react';
import { useQuery } from 'react-query';
import TidakAdaData from '../../components/TidakAdaData';
import { GET_ALL_ANGGOTA } from '../../api/anggota';
import { GET_ALL_JENIS_SAMPAH } from '../../api/jenis_sampah';
import {GET_ALL_GROUP_ANGGOTA,GET_ALL_GROUP_ANGGOTA_FILTER} from '../../api/group_anggota';
import ButtonPrimary from '../../components/Button/ButtonPrimary';
import SelectInput from '../../components/SelectInput';
import TextInput from '../../components/TextInput';
import Struck from './Struck';
import CurrencyFormat from 'react-currency-format';
import { fRupiah, ribuan } from 'src/utils/formatNumber';
import { StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { format ,parseISO} from 'date-fns';
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */

export default function Form({
  next,
  mitra,
  dataStruck,
  setDataStruck,
  step,
  values,
  setValues,
  isLoading,
  handleAdd,
}) {
  const [loading, setLoading] = useState(false);
  const [group_anggota, setGroupAnggota] = useState();
  const [selectedGroup, setSelectedGroup] = useState('all'); // Initialize with the default value
  const [showForm, setShowFrom] = useState(false);
  const [sampahbutton, setsampahbutton] = useState(false);
  const [search, setSearch] = useState('');
  const [valuetanggal , Setvaluetanggal] = useState(null);
  const [form, setForm] = useState({ sumber: '', jsCode: '', harga: '', berat: '', jenis: '' });

  const [sampah, setSampah] = useState([]);
  const { data } = useQuery('GET_ALL_ANGGOTA', GET_ALL_ANGGOTA, {
    refetchOnMount: true,
  });
  const { data: dataJenis } = useQuery('GET_ALL_JENIS_SAMPAH', GET_ALL_JENIS_SAMPAH, {
    refetchOnMount: true,
  });
  const optionJs =
    dataJenis &&
    dataJenis?.data?.data.map((js) => {
      return { value: js.jsCode, label: js.jenis };
    });

  const anggotaNF = data && data?.data?.data;
  // console.log(anggotaNF)

  async function getAnggota() {
    setLoading(true);
    GET_ALL_GROUP_ANGGOTA_FILTER()
      .then((res) => {
        const list =
          res &&
          [
            { value: 'all', label: 'All' }, // Add the "All" option
            ...res.data?.data?.map((p) => ({ value: p.gaCode, label: p.gaDescription })),
          ];
          setGroupAnggota(list);
      })
      .catch((e) => {
        setGroupAnggota();
        console.log(e);
      });
    setLoading(false);
  }

  // async function getAnggota() {
  //   setLoading(true);
  //   try {
  //     const res = await GET_ALL_GROUP_ANGGOTA();
  //     const list =
  //     res &&
  //     [
  //       { value: 'all', label: 'All' }, // Add the "All" option
  //       ...res.data?.data?.map((p) => ({ value: p.gaCode, label: p.gaDescription })),
  //     ];
  //     setGroupAnggota(list);
      
  //   } catch (e) {
  //     setGroupAnggota([]);
  //     console.error("Error fetching group anggota:", e);
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  

  useEffect(() => {
    getAnggota();
  }, []); 



  const handleOpen = (a, s, val) => {
    setLoading(true);
    if (s === 3) {
      handleAdd({ ...values, ...val });
    } else {
      setValues({ ...values, ...val });
      next(a, s);
    }
    setLoading(false);
  };

  const removeListSampah = (index) => {
    const valuess = [...sampah];
    valuess.splice(index);
    setSampah(valuess);
  };
  const handelSimpan = () => {
    setSampah([...sampah, form]);
    setSampah([...sampah, form]);
    setForm({ sumber: '', jsCode: '', harga: '', berat: '', jenis: '' });
    setShowFrom(false);
    setsampahbutton(true);
  };
  const handleJenis = (e) => {
    const jenis = optionJs.find((j) => j.value === e.target.value);
    setForm({ ...form, jsCode: e.target.value, jenis: jenis.label });
  };
  const handlePilihAnggota = (anggota) => {
    // console.log(anggota);
    setDataStruck({
      ...dataStruck,
      anggota,
      mitra: { alamat: mitra?.alamat, namaUsaha: mitra?.gudang[0]?.namaUsaha },
    });
    handleOpen('PEMBELIAN BAHAN DUP', 2, { anggotaCode: anggota?.anggotaCode });
    console.log(step);
  };
  const handleTambahSampah = async () => {
    await setDataStruck({ ...dataStruck, detail: sampah });
    await handleOpen('Struck', 3, { detail: sampah });
  };
  // const filterAnggota = (array, query) => {
  //   if (query) {
  //     const column = array[0] && Object.keys(array[0]);
  //     return array.filter((a) =>
  //       column.some((col) => a[col] && a[col].toString().toLowerCase().indexOf(query.toLowerCase()) > -1)
  //     );
  //   }
  //   return array;
  // };

  const filterAnggota = (array, query, selectedGroup) => {
    if (query || selectedGroup !== 'all') {
      const column = array[0] && Object.keys(array[0]);
      return array.filter((a) => {
        const matchesQuery = column.some(
          (col) => a[col] && a[col].toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        );
  
        const matchesGroup = selectedGroup === 'all' || a.gaCode === selectedGroup;
  
        return matchesQuery && matchesGroup;
      });
    }
    return array;
  };
  
  // const anggota = filterAnggota(anggotaNF, search);
  const anggota = filterAnggota(anggotaNF, search, selectedGroup);
  const handleTanggal = async (e) => {
    
    await setDataStruck({ ...dataStruck, createAt: valuetanggal });
    
    handleOpen('Pilih Supplier', 1,{ createAt: valuetanggal });
   
  }
  return (
    <form>
      {step === 0 && (
      <>
      <StaticDateTimePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        inputFormat="dd/MM/yyyy"
        value={valuetanggal}
        onChange={(newValue) => {
          Setvaluetanggal(format(new Date(newValue), 'yyyy-MM-dd HH:mm:ss'));
        }}
        renderInput={(params) => <TextField {...params} />}
      />
           <ButtonPrimary
              disabled={valuetanggal === null}
              onClick={handleTanggal}
              label='Lanjut'
            />
      
      </>
      )}
      {step === 1 && (
        <>
                  <SelectInput
            label="Group Anggota"
            name="group_anggota"
            id="group_anggota"
            option={group_anggota}
            value={selectedGroup}
            onChange={(e) => {
              setSelectedGroup(e.target.value);
              setSearch(''); // Reset search when group changes
            }}
          />
          <TextInput value={search} onChange={(e) => setSearch(e.target.value)} label="Cari Anggota" />
          {anggota && anggota.length === 0 && <TidakAdaData />}
          <Grid sx={{ padding: 3 }} container spacing={2}>
            {anggota &&
              anggota.map((m, i) => (
                <Grid onClick={() => handlePilihAnggota(m)} key={i} item xs={6}>
                  <Card
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
                  >
                    <CardContent
                      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: 15 }}
                    >
                      <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="body1">
                        {m.nama}
                      </Typography>
                      <Typography style={{ fontWeight: 'bold', marginBottom: 10, fontSize: 11 }} variant="body2">
                        {m.nik}
                      </Typography>
                      <Typography style={{ fontSize: 10 }} variant="group_anggota">
                        {m.group_anggota}
                      </Typography>
                      <Typography style={{ fontSize: 10 }} variant="alamat">
                        {m.alamat}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {step === 2 && (
        <>
          {sampah &&
            sampah.map((m, i) => (
              <Card key={i} style={{ marginBottom: 10 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }}>{m?.jenis}</Typography>
                      <Typography sx={{ fontSize: 12 }}>Sumber : {m?.sumber}</Typography>
                      <Typography sx={{ fontSize: 12 }}>Berat : {ribuan(m?.berat)}</Typography>
                      <Typography sx={{ fontSize: 12 }}>Harga : {fRupiah(m?.harga)}</Typography>
                      <Button onClick={() => removeListSampah(i)} size="small" variant="outlined" color="error">
                        Hapus
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          <Button
            variant="outlined"
            style={{ marginBottom: 10 }}
            onClick={() => {setShowFrom(!showForm); setsampahbutton(showForm)}}
            color="success"
          >
            {showForm ? 'Batal' : 'Tambah Sampah'}
          </Button>
          <form>
            {showForm && (
              <>
                <SelectInput
                  label={'Sumber Sampah'}
                  name="sumber"
                  id="sumber"
                  onChange={(e) => setForm({ ...form, sumber: e.target.value })}
                  value={form.sumber}
                  option={['Perkantoran', 'Perumahan', 'Kawasan Industri', 'Fasilitas Umum', 'Fasilitas Khusus'].map(
                    (va) => {
                      return { value: va, label: va };
                    }
                  )}
                />
                <SelectInput
                  label={'Jenis Sampah'}
                  name="jsCode"
                  id="jsCode"
                  onChange={handleJenis}
                  value={form.jsCode}
                  option={optionJs && optionJs}
                />
              
                <CurrencyFormat 
                fullWidth 
                label={'Berat (kg)'} 
                customInput={TextField} 
                style={{paddingBottom:15 ,paddingTop:15}} 
                onValueChange={(e) => setForm({ ...form, berat: e.value })}
                thousandSeparator={true} 
                type="tel"
                value={form.berat} />
                
                <CurrencyFormat 
                fullWidth 
                customInput={TextField}
                thousandSeparator={true} 
                style={{paddingBottom:15 ,paddingTop:15}}
                type="tel"
                onValueChange={(e) => setForm({ ...form, harga: e.value })}
                value={form.harga}
                label={'Harga (Rp per kg)'}
               />
{/*                 
                <TextInput
                  id="berat"
                  name="berat"
                  type="number"
                  onChange={(e) => setForm({ ...form, berat: e.target.value })}
                  value={form.berat}
                  label={'Berat'}
                /> */}
                <ButtonPrimary
                  onClick={handelSimpan}
                  disabled={form.berat === '' || form.harga === '' || form.jsCode === '' || form.sumber === ''}
                  style={{ marginTop: 30, marginBottom: 5 }}
                  label={'Tambah'}
                />
              </>
            )}
            <ButtonPrimary
              type="submit"
              disabled={sampah.length === 0 || sampahbutton===false || loading || isLoading}
              onClick={handleTambahSampah}
              label={isLoading ? 'Proses' : 'Simpan Pembelian'}
            />
          </form>
        </>
      )}
      {step === 3 && (
        <>
          <Struck data={dataStruck} />
          <ButtonPrimary disabled={loading || isLoading} onClick={handleAdd} label="Selesai" />
        </>
      )}
    </form>
  );
}

Form.propTypes = {
  next: PropTypes.any,
  step: PropTypes.any,
  mitra: PropTypes.any,
  setValues: PropTypes.any,
  isLoading: PropTypes.any,
  values: PropTypes.any,
  dataStruck: PropTypes.func,
  setDataStruck: PropTypes.func,
  handleAdd: PropTypes.func,
};
