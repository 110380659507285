import qs from 'qs';
import axios, { catchCallBack } from '../index';

const GET_DESCRIPTION = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/description', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};


export {GET_DESCRIPTION};
