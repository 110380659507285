import qs from 'qs';
import axios, { catchCallBack } from '../index';

const GET_ALL_GROUP_ANGGOTA = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/groupAnggota/all', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ALL_GROUP_ANGGOTA_MOBILE = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/groupAnggota/all/mobile', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ALL_GROUP_ANGGOTA_FILTER = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/groupAnggota/all/filter', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};


const GET_ONE_GROUP_ANGGOTA = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get(`master/groupAnggota/one/${id}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};


const ADD_GROUP_ANGGOTA = async ({  gaDescription, mitra }) => {
  console.log('gaDescription before stringify:', gaDescription);
    // const formattedDescription = formatDescription(gaDescription); // Example function
  // const validatedDescription = validateDescription(gaDescription); // Example function
  const data = qs.stringify({
    gaDescription,
    mitra,
  }, );
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.post(`master/groupAnggota/add`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const ADD_GROUP_ANGGOTA_MOBILE = async ({  gaDescription, mitra }) => {
  const data = qs.stringify({
    gaDescription,
    mitra,
  });
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.post(`master/groupAnggota/add/mobile`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};


const UPDATE_GROUP_ANGGOTA = async ({ gaDescription }, id,id2) => {
  const data = qs.stringify({
    gaDescription
  });
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.put(`master/groupAnggota/edit/${id}/${id2}`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};
// const DELETE_GROUP_ANGGOTA = async (id) => {
//   const headers = {
//     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//   };
//   try {
//     const response = await axios.delete(`master/groupAnggota/delete/${id}`, { headers });
//     return response;
//   } catch (error) {
//     return catchCallBack(error);
//   }
// };

const UPDATE_GROUP_ANGGOTA_MOBILE = async ({ gaDescription }, id) => {
  const data = qs.stringify({
    gaDescription
  });
const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.put(`master/groupAnggota/mobile/edit/${id}`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};
  
const DELETE_GROUP_ANGGOTA = async (id,id2) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.delete(`master/groupAnggota/delete/${id}/${id2}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};


const GET_ANGGOTA_COUNT = async (id,id2) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get(`master/groupAnggota/anggota/count/${id}/${id2}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

export { ADD_GROUP_ANGGOTA, GET_ALL_GROUP_ANGGOTA, DELETE_GROUP_ANGGOTA, UPDATE_GROUP_ANGGOTA, GET_ONE_GROUP_ANGGOTA,GET_ALL_GROUP_ANGGOTA_FILTER,GET_ALL_GROUP_ANGGOTA_MOBILE,ADD_GROUP_ANGGOTA_MOBILE,UPDATE_GROUP_ANGGOTA_MOBILE,GET_ANGGOTA_COUNT };
