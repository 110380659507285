import { Typography } from '@mui/material';
import React from 'react';
import adupi from '../assets/logo/logo.png';
import leMineral from '../assets/logo/le-minerale.png';

export default function AdupiXLeMineraleHead({ text = false }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}>

    </div>
  );
}
