import { useState,useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import ButtonPrimary from '../../../components/Button/ButtonPrimary';
import TextInput from '../../../components/TextInput';
import { GET_DESCRIPTION } from '../../../api/termCondition';

export default function Step7({ handleNext, isLoading }) {
  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      console.log(values);
      handleNext(0, 'Done');
    },
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  useEffect(() => {
    setLoading(true);
    GET_DESCRIPTION()
      .then((res) => {
        // Check if res.data is defined and is an array with at least one item
       setDescription(res.data.data[0].description)
      })
      .catch((error) => {
        // Handle errors from the API request
        console.error("Error fetching description:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const termsAndConditionsText = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla imperdiet semper orci, at fermentum dui vehicula sit amet. Nulla ultricies elit a eros ullamcorper consequat. Aliquam erat volutpat. Donec volutpat justo id quam lacinia, in malesuada quam tempor. Duis hendrerit tortor eu nulla sodales, eget posuere mi pharetra. Cras et ex magna. Fusce in tortor ac dolor aliquet lobortis. Maecenas consectetur odio in turpis dapibus, nec mattis nisi interdum. Sed eleifend risus sit amet ex scelerisque, ut condimentum mi tempor. Integer euismod metus id velit ultricies suscipit. Nam gravida, ligula nec pharetra tempor, odio ex vulputate eros, vel sollicitudin dolor enim sit amet quam. Duis sodales, nisi ut accumsan tempor, lorem justo fringilla nisi, et condimentum orci nunc sit amet lorem. Nullam at neque eu eros scelerisque varius.

  Phasellus sed aliquam lorem, at dapibus eros. Suspendisse potenti. Vivamus semper libero in ligula dignissim vestibulum. Pellentesque consequat lorem ac sapien commodo, vitae faucibus velit pellentesque. Sed bibendum, magna id viverra rhoncus, turpis nisi interdum felis, vel tincidunt magna odio at velit. Quisque gravida, magna sit amet convallis sodales, enim velit tristique lorem, non suscipit quam nunc nec magna. Ut nec velit magna. Ut gravida nulla eu nisi feugiat commodo. Phasellus vitae libero nec est convallis pharetra. Nullam ullamcorper mauris sed urna luctus, ac consectetur nunc lobortis.

  Fusce ultrices vehicula elit, in mattis dui vehicula eget. Nulla facilisi. Nam tempus ligula sed tortor suscipit, nec vestibulum lorem pharetra. In at lobortis risus. Nulla facilisi. Nulla congue, dolor vitae pharetra suscipit, odio diam rutrum lacus, nec ultrices elit purus id neque. Ut laoreet nulla ac turpis bibendum, sit amet ultricies nisl volutpat. Integer eget ultrices nibh. Proin sed massa eu nisi lobortis congue. Mauris vestibulum malesuada lectus, in cursus mauris finibus vitae. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
`;

  return (
    <form onSubmit={formik.handleSubmit}>
      
      <div style={{ marginLeft: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px',maxHeight: '500px', overflowY: 'auto' }}>
      <div style={{ margin: 0 }}> 
        <div dangerouslySetInnerHTML={{ __html: description }} /> 
        </div>  
        </div>
      <div style={{ display: 'flex', alignItems: 'center',marginLeft: '20px', marginTop: '10px',marginBottom: '10px'  }}>
        <label>
          <input
            type="checkbox"
            name="termsAndConditions"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span style={{ marginLeft: '5px' }}>
          Saya telah membaca, memahami, dan menyetujui Pernyataan Kebijakan Privasi ini
          </span>
        </label>
      </div>
      <ButtonPrimary disabled={!isChecked || isLoading} type="submit" label="Daftar" />
    </form>
  );
}

Step7.propTypes = {
  handleNext: PropTypes.func,
  isLoading: PropTypes.any,
};
