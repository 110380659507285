import { Button, Card, Container, Stack, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
// import { useMee } from 'contexts/MeContext';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';

  import {
    GET_ALL_GROUP_ANGGOTA,
    UPDATE_GROUP_ANGGOTA,
    ADD_GROUP_ANGGOTA,
    DELETE_GROUP_ANGGOTA,
    GET_ALL_GROUP_ANGGOTA_MOBILE,
    ADD_GROUP_ANGGOTA_MOBILE,
    UPDATE_GROUP_ANGGOTA_MOBILE,
    GET_ANGGOTA_COUNT,
  } from '../../api/group_anggota';

import DialogConfirm from '../../components/DialogConfirm';
import Page from '../../components/Page';
import useTable from '../../hooks/useTable/index';
import Action from './Action';
import DialogComponent from './DialogComponent';
import { convertSpeed } from 'geolib';
import AdupiXLeMineraleHead from '../../components/AdupiXLeMineraleHead';
import BarMobile from '../../components/BarMobile';

const headCells = [
    {
      id: 'gaDescription',
      numeric: false,
      disablePadding: true,
      label: 'Group Anggota',
    },
    
    // {
    //   id: 'kategori',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Kategori Sampah',
    // },
  ];
  
  export default function Index() {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertText, setAlertText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [itemSelected, setItemSelected] = React.useState(null);
    //   const { checkPermision } = useMee();
    // const { data, isLoading, refetch } = useQuery('GET_ALL_JENIS_MITRA', GET_ALL_JENIS_MITRA);
    const { data, isLoading, refetch } = useQuery('GET_ALL_GROUP_ANGGOTA_MOBILE', GET_ALL_GROUP_ANGGOTA_MOBILE);
    const { enqueueSnackbar } = useSnackbar();
  
    const rows = data && data?.data?.data;
  
    const { TableComponent, list } = useTable({
      header: headCells,
      rows: rows || [],
      loading: isLoading,
    });
    // HANDLE ACTION
    const handleActionOpen = (event, item) => {
      console.log("Selected Item:", item);
      setItemSelected(item);
      setAnchorEl(event.currentTarget);
    };
    const handleActionClose = () => {
      setItemSelected(null);
      setAnchorEl(null);
    };
    // HANDLE MODAL
    const handleEdit = () => {
      setDialogOpen(true);
    };
  
    // HANDLE ALERT
    const handleAlertOpen = (text) => {
      setAlertText(text);
      setAlertOpen(true);
    };
    const handleAlertClose = () => {
      setAlertText('');
      setAlertOpen(false);
    };

    const checkAnggotaMutation = useMutation(
      (params) => GET_ANGGOTA_COUNT(params.id,params.mitraCode),
      {
        onSuccess: async (data) => {
          const { count, message } = data.data.data;
          console.log(data.data.data.count)
          const variant = count > 0 ? 'warning' : 'success';
          if (count > 0) {
            enqueueSnackbar("Data Anggota Masih ada", { variant: 'warning' });
            await refetch();
            handleActionClose();
            handleAlertClose();
            setItemSelected(null);
          } else {
            deleteMutation.mutate({ id: itemSelected.gaCode, mitraCode: itemSelected.mitraCode });
          }
        },
        onError: async (error) => {
          console.error("Error fetching anggota count:", error);
          await enqueueSnackbar(error.message, 'error');
        },
      }
    );
  
    const deleteMutation = useMutation((params) => DELETE_GROUP_ANGGOTA(params.id,params.mitraCode), {
      onSuccess: async (res) => {
        const variant = res.status === 200 ? 'success' : 'warning';
        await enqueueSnackbar(res.data.message, { variant });
        await refetch();
        handleActionClose();
        handleAlertClose();
        setItemSelected(null);
      },
      onError: async (e) => {
        await enqueueSnackbar(e.message, 'error');
      },
    });
    // HANDLE ACTION
    const onAdd = async (data, callbackSetError) => {
      setLoading(true);
      console.log(data)
      const response = await ADD_GROUP_ANGGOTA_MOBILE(data);
      if (response.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.status === 422) {
        const asdf = response.data.errors;
        const keys = asdf && Object.keys(asdf);
        keys.forEach((key) => {
          enqueueSnackbar(asdf[key].msg, { variant: 'warning' });
        });
      }
      if (response.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        setDialogOpen(false);
        handleActionClose();
      }
      await setLoading(false);
    };
    const onUpdate = async (data, id, callbackSetError) => {
      setLoading(true);
      const response = await UPDATE_GROUP_ANGGOTA_MOBILE(data, id);
      if (response.data.status === 400) {
        callbackSetError(response.data.error.form);
      }
      if (response.data.status === 200) {
        await refetch();
        await enqueueSnackbar(response.data.message, { variant: 'success' });
        handleActionClose();
        setDialogOpen(false);
        setItemSelected(null);
      }
      await setLoading(false);
    };
    const handleConfirm = async () => {
      try {
        console.log(itemSelected.gaCode)
        console.log(itemSelected.mitraCode)
        checkAnggotaMutation.mutate({
          id: itemSelected.gaCode,
          mitraCode: itemSelected.mitraCode,
        }); 
      } catch (error) {
        console.error("Error performing mutation:", error);
      }
    };
    
  
    const actionOpen = Boolean(anchorEl);
    const processing = loading || isLoading || deleteMutation.isLoading;
    return (
      <>
      <BarMobile title={'Tambah Group Anggota'} />
      <AdupiXLeMineraleHead />
      <Page title="Group Anggota">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Group Anggota
            </Typography>
            <Button onClick={() => setDialogOpen(true)} variant="contained">
              Tambah
            </Button>
            {/* )} */}
          </Stack>
  
          <Card>
            {list &&
              TableComponent(
                list.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow onClick={(event) => handleActionOpen(event, row)} hover tabIndex={-1} key={index}>
                      <TableCell>{row.no}</TableCell>
                      <TableCell id={labelId} scope="row">
                        {row.gaDescription}
                      </TableCell>
                      {/* <TableCell id={labelId} scope="row">
                        {row.kategori}
                      </TableCell> */}
                    </TableRow>
                  );
                })
              )}
          </Card>
        </Container>
        {dialogOpen && (
          <DialogComponent
            processing={processing}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onClose={() => setDialogOpen(false)}
            item={itemSelected}
            open={dialogOpen}
          />
        )}
        {actionOpen && (
          <Action
            actionOpen={actionOpen}
            handleEdit={handleEdit}
            handelDelete={() => handleAlertOpen('Apakah yakin mau delete')}
            anchorEl={anchorEl}
            actionClose={handleActionClose}
          />
        )}
        {alertOpen && (
          <DialogConfirm
            processing={processing}
            alertClose={handleAlertClose}
            alertOpen={alertOpen}
            handleConfirm={handleConfirm}
            text={alertText}
          />
        )}
      </Page>
      </>
    );
  }

