import qs from 'qs';
import axios, { catchCallBack } from '../index';

const GET_ALL_JENIS_MITRA = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/jenisMitra/all', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ALL_JENIS_MITRA_REGISTRATION = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get('master/jenisMitra/all/registration', { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const GET_ONE_JENIS_MITRA = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.get(`master/jenisMitra/one/${id}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};

const ADD_JENIS_MITRA = async ({ jmDescription }) => {
  const data = qs.stringify({
    jmDescription
  });
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.post(`master/jenisMitra/add`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};




const UPDATE_JENIS_MITRA = async ({ jmDescription }, id) => {
  const data = qs.stringify({
    jmDescription
  });
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.put(`master/jenisMitra/edit/${id}`, data, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};
const DELETE_JENIS_MITRA = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  try {
    const response = await axios.delete(`master/jenisMitra/delete/${id}`, { headers });
    return response;
  } catch (error) {
    return catchCallBack(error);
  }
};
export { ADD_JENIS_MITRA, GET_ALL_JENIS_MITRA, DELETE_JENIS_MITRA, UPDATE_JENIS_MITRA, GET_ONE_JENIS_MITRA, GET_ALL_JENIS_MITRA_REGISTRATION};
